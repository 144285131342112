import axios from 'axios';
import moment from 'moment';
import CSwl from './alert';
import { getLocalStorage } from "./localStorage";
import 'moment/locale/th';

export const setHeaderAuth = async () => {
  const token = await getLocalStorage("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const Currency = (number = 0, format = 0) => {
  if (format == 0) {
    return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(number).replace("฿", "").replace(/\D00$/, '');
  } else {
    return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB', minimumFractionDigits: format }).format(number).replace("฿", "");
  }
}

export const ConvertDate = (date, format = "DD/MM/YYYY HH:mm:ss", locale = 'en',addHours = 0) => {
  if (locale == "th") {
    return moment(date).add(addHours,'hours').locale(locale).format(format);
  } else {
    return moment(date).add(addHours,'hours').locale(locale).format(format);
  }

}

export const CheckFile = ({ file, size, type = [], message = "" }) => {
  if (!type.includes(file.type) && type.length > 0) {
    CSwl.SwalErr(
      `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
    );
    return false;
  }
  if (file.size / 1000 / 1000 > size) {
    CSwl.SwalErr(
      `Please upload a file smaller than ${size} MB`
    );
    return false;
  } else {
    return true;
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

export const validateNumber = (inputText) => {
  if (inputText.length == 0) {
    return true;
  }
  var numbers = /^[0-9]+$/;
  if (inputText.match(numbers)) {
    return true;
  } else {
    return false;
  }
}


export const validatePhonenumber = (inputText) => {
  var phoneno = /^\d{10}$/;
  if (inputText.length == 0) {
    return true;
  }
  if (inputText.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}



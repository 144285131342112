import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiCheckUp extends Component {
  static getCheckup = async () => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "checkup/checkupPhoto",
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getCheckupNew = async () => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "checkup/checkupPhotoNew",
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  static getCheckupExportData = async (dpNo) => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: `checkup/checkupExportData?dpNo=${dpNo}`,
        method: "get",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
  
  static finishCheckup = async () => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "image/checkup/finish",
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };

  static finishCheckupCheck = async () => {
    try {
      await setHeaderAuth();
      const result = await axios({
        url: "image/checkup/finishCheckupCheck",
        method: "post",
      });
      return result;
    } catch (error) {
      return error.response;
    }
  };
}

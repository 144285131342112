import logo from './logo.svg';
import './App.scss';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from './pages/login';

import Layout from './conponents/Layout';
import Home from './pages/home';
import Job from './pages/job';
import ScrollToTop from './utils/scrollToTop';
import JobTimeline from './pages/jobTimeline';
import Check from './pages/check';
import LoginSuccess from './pages/login/loginSuccess';
import Logout from './pages/login/logout';
import NoMatch from './pages/nomatch'
import ProfilePage from './pages/profile';
import ExportDataPage from './pages/exportData';
import ExportDataCheckPage from './pages/exportDataCheck';
import CheckNewPage from './pages/checkNew';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="job" element={<Job />} />
          <Route path="jobTimeline" element={<JobTimeline />} />
          <Route path="exportData" element={<ExportDataPage />} />
          <Route path="exportData/check" element={<ExportDataCheckPage />} />
          <Route path="check" element={<Check />} />
          <Route path="checkNew" element={<CheckNewPage />} />
          <Route path="profile" element={<ProfilePage />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/loginSuccess" element={<Login />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
}



export default App;
